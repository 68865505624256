import React, { useContext, useEffect, useState } from "react";
import { globalContext } from "../../../App";
import { CloseButton, Modal } from "react-bootstrap";
import { LoadingButton } from "../../helpers/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { SERVER_BASE_URL } from "../../../config";
import { useTranslation } from "react-i18next";

function ContactModal(props) {
  const [contact, setContact] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [status, setStatus] = useState(false);

  const { storeData, refetchStoreData } = useContext(globalContext);
  const {t} = useTranslation()

  const handleDelete = async () => {
    const token = localStorage.getItem("logToken");
    const url = SERVER_BASE_URL + `/contact/delete/${contact.id}`;
    const res = await fetch(url, {
      headers: {
        Authorization: `Token ${token}`,
      },
      method: "delete",
    });
    const resJ = await res.json();
    if (resJ.res === "ok") {
      await refetchStoreData();
      // todo: change to only remove from storeData
      props.setShowModal(false);
    } else {
      window.alert("Error 22212");
    }
  };

  const handleStatusChange = (id) => {
    const new_status = !contact.status;
    const token = localStorage.getItem("logToken");
    const url = SERVER_BASE_URL + "/contact/update";
    const params = {
      contact_id: id,
      status: new_status,
    };

    return fetch(url, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
      method: "put",
    })
      .then((res) => {
        res.json().then((resJ) => {
          if (resJ.res === "ok") {
            refetchStoreData();
            setStatus(new_status);
          } else {
            window.alert(t('gerr'));
          }
        });
      })
      .catch((e) => {
        console.log(`Error at fetchng status-update. error: ${e}`);
        window.alert(t('gerr'));
      });
  };

  useEffect(() => {
    if (storeData) {
      const temp = storeData.contacts.filter((c) => {
        return c.id == props.contactId;
      });
      setContact(temp[0]);
      setStatus(temp[0].status);
    }
  }, []);

  return (
    <>
      {contact.created && (
        <Modal show={props.showModal} onHide={() => props.setShowModal(false)}>
          <div className="modal-title1">
              <h4>{t('contactT1')} {contact.name}</h4>
              <CloseButton onClick={() => props.setShowModal(false)}/>            
          </div>
          <Modal.Body>
            <label className="fl"> {t('contactContent')}</label>
            <textarea
              className="fi"
              
              disabled
              value={contact.content}
            />
            <br />
            <label className="fl"> ID</label>
            <input className="fi ltr" disabled value={contact.id} />
            <br />
            <label className="fl"> {t('date')}</label>
            <input className="fi" style={{textAlign: 'left'}} disabled value={contact.created.slice(0, 10) + "   " + contact.created.slice(11, 16)} />
            <br />
            <label className="fl"> Email </label>
            <input className="fi" style={{textAlign: 'left'}} disabled value={contact.email} />
            <br />
            <label className="fl"> {t('clientTel')}</label>
            <input className="fi" style={{textAlign: 'left'}} disabled value={contact.telephone} />
            <br />

            <label className="fl"> {t('contactDone')}</label>

            <div >
              {spinner && <div className="spinner-border text-success"> </div>}
              <input
                id={contact.id}
                style={{ marginRight: "10%" }}
                type="checkbox"
                checked={status}
                onChange={(e) => {
                  setSpinner(true);
                  handleStatusChange(contact.id).then(() => {
                    setSpinner(false);
                  });
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LoadingButton
              variant="contained"
              color="error"
              onClick={handleDelete}
              loadingTxt={t('deleting')}
            >
              <DeleteIcon />
            </LoadingButton>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default ContactModal;
