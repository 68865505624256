import React, { useContext, useState } from "react";
import MsgModal from "./MsgModal";
import { globalContext } from "../../../App";

function Msg(props) {
  const [showModal, setShowModal] = useState(false);

  const { storeData } = useContext(globalContext);
  let msg = storeData.msgs.filter((m) => {
    return m.id === props.id;
  });
  msg = msg ? msg[0] : null;

  return (
    <>
      {msg && (
        <div
          onClick={() => {
            setShowModal(true);
          }}
          className="msgs"                    
        >
          <div style={{width: '30%'}}> {msg.title} </div>
          <div style={{width: "60%", textAlign: 'left'}}> {msg.content.slice(0, 20)}... </div>
        </div>
      )}

      {showModal && (
        <MsgModal
          handleUpdate={props.handleUpdate}
          showModal={showModal}
          setShowModal={setShowModal}
          msg={msg}
        />
      )}
    </>
  );
}

export default Msg;
