import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MainButton from "./helpers/MainButton";
import { Button } from "@mui/material";

const ColorsSuggestion = (props) => {
  const [option, setOption] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    // const resetColors = () => {
    //     setColor({ 1: "#ffffff", 2: "#f7aff8", 3: "#5C469C", 4: "#D4ADFC" });
    //     setUpdateButton(true)
    //   };
    switch (option) {
      case 0:
        props.setColor({
          1: "#ffffff",
          2: "#f7aff8",
          3: "#5C469C",
          4: "#D4ADFC",
        });
        break;
      case 1:
        props.setColor({
          1: "#FF5733",
          2: "#FFFFFF",
          3: "#FF914D",
          4: "#333333",
        });
        break;
      case 2:
        props.setColor({
          1: "#0074E4",
          2: "#F2F2F2",
          3: "#3E8FFF",
          4: "#333333",
        });
        break;
      case 3:
        props.setColor({
          1: "#4CAF50",
          2: "#FFFFFF",
          3: "#61D869",
          4: "#333333",
        });
        break;
      case 4:
        props.setColor({
          1: "#E6A1E9",
          2: "#F5F5F5",
          3: "#FF82B2",
          4: "#333333",
        });
        break;

      default:
        break;
    }

    if (option != "") {
      props.setUpdateButton(true);
    }
  }, [option]);
    return (<>
    <div style={{display:'flex'}}>
      <div className="color-button" style={{width:'7px', padding: '5px'}}></div>
      <div>
        <div>
          <Button
            variant="outlined"
            sx={{ width: "250px" }}
            onClick={() => {
                setOption(0);
            }}
            >
            {t("colorPel0")}
          </Button>
        </div>
        <div>
          <Button
            variant="outlined"
            sx={{ width: "250px" }}
            onClick={() => {
                setOption(1);
            }}
          >
            {t("colorPel1")}
          </Button>
        </div>
        <div>
          <Button
            sx={{ width: "250px" }}
            variant="outlined"
            onClick={() => {
              setOption(2);
            }}
            >
            {t("colorPel2")}
          </Button>
        </div>
        <div>
          <Button
            sx={{ width: "250px" }}
            variant="outlined"
            onClick={() => {
                setOption(3);
            }}
            >
            {t("colorPel3")}
          </Button>
        </div>
        <div>
          <Button
            sx={{ width: "250px" }}
            variant="outlined"
            onClick={() => {
                setOption(4);
            }}
            >
            {t("colorPel4")}
          </Button>
        </div>
        <div>
        
         
          <br />          
          <input
            type="color"
            value={props.color[1]}
            onChange={(e) => props.updateColor(e, 1)}
          />
          <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
          {t('mainColor')}
          </label>
          <br />
          <input
            type="color"
            value={props.color[2]}
            onChange={(e) => props.updateColor(e, 2)}
          />
          <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
          {t('secColor')}
          </label>
          <br />
          <input
            type="color"
            value={props.color[3]}
            onChange={(e) => props.updateColor(e, 3)}
          />
          <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
          {t('col3')}
          </label>
          <br />
          <input
            type="color"
            value={props.color[4]}
            onChange={(e) => props.updateColor(e, 4)}
          />
          <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
          {t('col4')}
          </label>
          <br />
        </div>
      </div>
        </div>
              </>
  );
};

export default ColorsSuggestion;
