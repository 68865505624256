import { SERVER_BASE_URL } from "../config";
import { getPresignedUrlFromServer, uploadImageToS3 } from "./s3-client";

export async function createProduct(obj) {
  if (
    !(
      ("mkt" in obj) &
      ("name" in obj) &
      // ("description" in obj) &
      ("price" in obj) &
      ("available" in obj)
    )
  ) {
    alert("Can't create product without all fields");
    return
  }

  console.log("About to create product with: ");
  console.log(obj);

  const token = localStorage.getItem("logToken");

  const params = obj;

  const options = {
    headers: {
      Authorization: `Token ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(params),
  };

  try {
    const res = await fetch(SERVER_BASE_URL + "/create_products", options);
    res.json().then((resJ) => {
      console.log(resJ);
      if (resJ.includes("product added")) {
      } else {
        window.alert(`Error updating product: response: ${resJ}`);
      }
    });
  } catch (e) {
    window.alert(`Error updating product: error: ${e}`);
  }
}

export async function deleteProduct(mkt) {
  console.log("About to delete a product mkt:");
  console.log(mkt);

  const token = localStorage.getItem("logToken");

  const options = {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "DELETE",
    body: JSON.stringify({ mkts: [mkt] }),
  };

  return fetch(SERVER_BASE_URL + "/delete_products", options)
    .then((res) => {
      res.json().then((resJ) => {
        if (resJ.includes("products deleted")) {
          console.log(resJ);
        } else {
          window.alert(`Error deleting product: response: ${resJ}`);
        }
      });
    })
    .catch((e) => {
      window.alert(`Error updating product: error: ${e}`);
    });
}

export async function updateProduct(obj) {
  console.log("About to update a product with: ");
  console.log(obj);

  const token = localStorage.getItem("logToken");

  const params = obj;

  const options = {
    headers: {
      Authorization: `Token ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "PUT",
    body: JSON.stringify(params),
  };

  let res = ""
  let resJ = ""
  try {
    res = await fetch(SERVER_BASE_URL + "/update_product", options);
    console.assert(res.status == 200)
    resJ = await res.json();
  } catch (error) {
    window.alert(`Error 110 updating product`);
    console.log(res)
    console.log(resJ)    
  }  
}

const _updateProductWithImageId = async (imageId) => {
  const token = localStorage.getItem("logToken");
  try {
    // build options:
    // Todo: get options from utils
    const options = {
      method: "put",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ image_id: imageId, new_status: "ok" }),
    };

    // send request:
    // TODO: create class for fetch requests
    // consider using axios
    const res = await fetch(
      SERVER_BASE_URL + "/product/image/update_status",
      options
    );

    // check response:
    if (res.status !== 200) {
      throw new Error("Error at updating product with image id");
    }
  } catch (error) {
    console.error(error);
  }
};

export async function addProductImage(mkt, img) {
  //  get presigned url from server:
  const preSignedUrlRes = await getPresignedUrlFromServer(mkt);

  if (!preSignedUrlRes) {
    // Todo: handle error if no presigned url
    return;
  }

  const { psurl: presignedUrl, image_id: imageId } = preSignedUrlRes;

  // upload image to s3:
  const isUploaded = await uploadImageToS3(presignedUrl, img);

  // update server that image uploaded to s3:
  isUploaded && (await _updateProductWithImageId(imageId));
  // Todo: handle error if image not uploaded to s3
}

export async function deleteProductImage(imageId) {
  console.log(`About to fetch delete image of id: ${imageId}`);

  const token = localStorage.getItem("logToken");

  const options = {
    method: "delete",
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ image_id: imageId }),
  };

  const res = await fetch(SERVER_BASE_URL + `/product/image/delete`, options);
  if (res.status !== 200) {
    window.alert(`Error at delete image.`);
    console.log("Error at delete image. res:");
    console.log(res);
  }
  const resJ = await res.json();
  console.log(`image deleted. res: ${resJ}`);
}
