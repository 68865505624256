import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// import "../../style.css"

import { SERVER_BASE_URL } from "../../../config";
import BackButton from "../../BackButton";
import { LoadingButton } from "../../helpers/LoadingButton";
import { globalContext } from "../../../App";
import { useTranslation } from "react-i18next";

function Links() {
  const { storeData, refetchStoreData } = useContext(globalContext);

  const [tenBis, setTenBis] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [wolt, setWolt] = useState("");
  const [whatsapp, setWhatsapp] = useState("");

  const {t} = useTranslation()
  const nav = useNavigate();

  useEffect(() => {
    if (storeData) {
      setTenBis(storeData.links.ten_bis);
      setFacebook(storeData.links.facebook);
      setInstagram(storeData.links.instagram);
      setWolt(storeData.links.wolt);
      setWhatsapp(storeData.links.whatsapp);
    }
  }, [storeData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const params = {
      ten_bis: tenBis,
      wolt: wolt,
      facebook: facebook,
      whatsapp: whatsapp,
      instagram: instagram,
    };

    const url = SERVER_BASE_URL + `/update_store`;
    const token = localStorage.getItem("logToken");
    const res = await fetch(url, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(params),
    });
    const resJ = await res.json();
    if (resJ.res === "ok") {
      await refetchStoreData();
      nav("/");
    } else {
      window.alert("שגיאה קרתה");
    }
  };

  return (
    <>
      {storeData && (
        <>
          <div
            style={{ color: "var(--color1)",  margin: "auto", maxWidth: '700px' }}
          >
            <h5>{t('linkTitle')}</h5>

            <label className="fl"> {t('tenb')} </label>
            <input
              className="fi ltr"
              type="text"
              value={tenBis}
              onChange={(e) => {
                setTenBis(e.target.value);
              }}
            />

            <label className="fl"> {t('fbL')} </label>
            <input
              className="fi ltr"
              type="text"
              value={facebook}
              onChange={(e) => {
                setFacebook(e.target.value);
              }}
            />

            <label className="fl">{t('woltL')} </label>
            <input
              className="fi ltr"
              type="text"
              value={wolt}
              onChange={(e) => {
                setWolt(e.target.value);
              }}
            />

            <label className="fl"> {t('instL')} </label>
            <input
              className="fi ltr"
              type="text"
              value={instagram}
              onChange={(e) => {
                setInstagram(e.target.value);
              }}
            />

            <label className="fl"> {t('waL')} </label>
            <input
              className="fi ltr"
              type="text"
              value={whatsapp}
              onChange={(e) => {
                setWhatsapp(e.target.value);
              }}
            />

            <br />
            <div
              style={{ textAlign: "left", marginLeft: "10%", marginTop: "5%" }}
            >
              <LoadingButton
                variant="contained"
                sx={{ backgroundColor: "var(--color2)" }}
                onClick={handleSubmit}
                loadingTxt={t('updating')}
              >
                {t('update')}
              </LoadingButton>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Links;
