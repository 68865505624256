import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MainButton from "./helpers/MainButton";
import {
  resetPasswordByCode,
  sendForgotPasswordCode,
} from "../clients/password-client";
import { useNavigate } from "react-router-dom";
import { isEmailValid } from "../helpers";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [pw1, setPw1] = useState("");
  const [pw2, setPw2] = useState("");
  const [showReset, setShowReset] = useState(false);

  const { t } = useTranslation();
  const nav = useNavigate();

  const stageAHandler = async () => {
    // send new password + code

    if (!isEmailValid(email)) {
      alert(t("emailNotFound"));
    }

    const res = await sendForgotPasswordCode(email);
    if (res == "ok") {
      setShowReset(true);
    } else if (res === "email not found") {
      alert(t("emailNotFound"));
    }
  };
  const stageBHandler = async () => {
    if (pw1 !== pw2) {
      alert(t("pwConstrain2"));
      return;
    }
    const res = await resetPasswordByCode(email, code, pw1);
    if (res === "ok") {
      alert(t("pwChanged"));
      nav("/login");
    } else {
      if (res === "wrong code") {
        alert(t("wrongCode"));
      }
    }
  };

  return (
    <div>
      <h4> {t("forgotPasswordTitle")}</h4>
      <br />
      {!showReset && (
        <div>
          <label> {t("enterEmailReset")}</label>
          <br />
          <input
            type="email"
            dir="ltr"
            placeholder="@email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <p> {t("emailConf")}</p>
          <br />
          <MainButton onClick={stageAHandler}> {t("sendCode")} </MainButton>
        </div>
      )}

      {showReset && (
        <div>
          <label> {t("resetPwCode")}</label>
          <br />
          <input
            dir="ltr"
            value={code}
            placeholder="code"
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
          <br />
          <label> {t("newPsw")}</label>
          <br />
          <input
            dir="ltr"
            type="password"
            value={pw1}
            placeholder="password"
            onChange={(e) => {
              setPw1(e.target.value);
            }}
          />
          <br />
          <label> {t("rePsw")}</label>
          <br />
          <input
            dir="ltr"
            type="password"
            value={pw2}
            placeholder="password again"
            onChange={(e) => {
              setPw2(e.target.value);
            }}
          />
          <br />

          <MainButton onClick={stageBHandler}> {t("send")} </MainButton>
        </div>
      )}

      <br />
    </div>
  );
};

export default ForgotPassword;
