import Modal from "react-bootstrap/Modal";
import React, { useContext, useState } from "react";
import { CloseButton, Table } from "react-bootstrap";

import "./order.css";

import {
  deleteOrder,
  fetchProductsOfOrder,
  updateOrder,
} from "../../../../helpers";
import { LoadingButton } from "../../../helpers/LoadingButton";
import { globalContext } from "../../../../App";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const calcTotal = (products) => {
  const pricesList = products.map((p) => {
    return p.product.price * p.amount;
  });
  return pricesList.reduce((partialSum, a) => partialSum + a, 0);
};

function Order(props) {
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState([]);
  const { refetchStoreData } = useContext(globalContext);
  const { t } = useTranslation();

  const loadProducts = () => {
    console.log(props.order);
    fetchProductsOfOrder(props.order.id, setProducts);
  };

  const handleDelete = async () => {
    await deleteOrder(props.order.id);
    await refetchStoreData();
    setShowModal(false);
  };

  const updateStatus = (new_status) => {
    if (new_status.includes("- -")) {
      return;
    }

    updateOrder(props.order.id, { status: new_status }).then(() => {
      refetchStoreData();
      setShowModal(false);
    });
  };

  return (
    <>
      {/* for pc:  */}
      <div
        onClick={() => {
          loadProducts();
          setShowModal(true);
        }}
        className="obuttons only-pc gs-list"
      >
        <div className="only-pc" style={{ width: "28%" }}>
          {" "}
          {props.order.order_id}
        </div>
        <div className="only-pc" style={{ width: "25%" }}>
          {" "}
          {props.order.client_name}
        </div>
        <div className="only-pc" style={{ width: "20%" }}>
          {" "}
          {props.order.status}
        </div>
        <div className="only-pc" style={{ width: "20%", textAlign: "left" }}>
          {props.order.created.slice(0, 10)} {props.order.created.slice(11, 16)}
        </div>
      </div>

      {/* for mobile:  */}
      <div
        onClick={() => {
          loadProducts();
          setShowModal(true);
        }}
        className="obuttons only-mobile gs-list"
      >
        <div style={{ width: "50%" }}> {props.order.client_name}</div>
        <div
          className="only-mobile"
          style={{ textAlign: "left", width: "50%" }}
        >
          {props.order.created.slice(0, 10)}
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >        

        <div className="modal-title1">
          <h4> {t("ordDetails")}</h4>
          <CloseButton onClick={() => setShowModal(false)} />
        </div>

        <Modal.Body>
          <div style={{}}>
            <div>
              {" "}
              {t("orderClient")}: {props.order.client_name}{" "}
            </div>
            <div>
              {t("date")}: {props.order.created.slice(0, 10)} -{" "}
              {props.order.created.slice(11, 16)}
            </div>
            <div>
              {" "}
              {t("orderComment")}: {props.order.comments}{" "}
            </div>
            <div>
              {" "}
              {t("clientTel")}: {props.order.client_telephone}{" "}
            </div>
            <div>
              {" "}
              {t("clientEmail")}:{props.order.client_email}{" "}
            </div>
            <div>
              {" "}
              {t("status")}: {props.order.status}{" "}
            </div>
            <br></br>

            <label htmlFor="id_status"> {t("updateStatus")}</label>
            <br />
            <select
              name="status"
              id="id_status"
              onChange={(e) => {
                updateStatus(e.target.value);
              }}
            >
              <option value="- -"> - - -</option>
              <option value="done">{t("done")}</option>
              <option value="pending">{t("waiting")}</option>
              <option value="decline">{t("rejected")}</option>
            </select>
          </div>

          <br />

          <Table striped bordered hover variant="light">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>{t("price")}</th>
                <th style={{ textAlign: "center" }}>{t("product")}</th>
                <th style={{ textAlign: "center" }}>{t("amount")}</th>
                <th style={{ textAlign: "center" }}>{t("mkt")}</th>
              </tr>
            </thead>
            <tbody>
              {products &&
                products.map((p) => {
                  return (
                    <>
                      <tr>
                        <td>{p.product.price}</td>
                        <td>{p.product.name}</td>
                        <td>{p.amount}</td>
                        <td dir="ltr">{p.product.mkt}</td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </Table>
          <p style={{ textAlign: "center" }}>
            {t("total")}
            {calcTotal(products)}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outlined"
            sx={{ backgroundColor: "var(--color3)", margin: "2px" }}
            onClick={() => {
              setShowModal(false);
            }}
          >
            {t("cancel")}
          </Button>

          <LoadingButton
            variant="contained"
            sx={{ backgroundColor: "var(--color1)" }}
            onClick={handleDelete}
            loadingTxt={t("deleting")}
          >
            {t("delete")}
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Order;
