import React, { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { LoadingButton } from "../../helpers/LoadingButton";
import { changePassword } from "../../../clients/password-client";
import { useTranslation } from "react-i18next";

function ChangePasswordModal(props) {
  const [pw, setPw] = useState("");
  const [pw1, setPw1] = useState("");
  const [pw2, setPw2] = useState("");

  const [error, setError] = useState(false);
  const {t} = useTranslation()

  const handleSubmit = async () => {
    if ((pw1 != pw2) | (pw1.length < 4) | (pw.length < 4)) {      
      setError(true);
      return;
    }
    const res = await changePassword(pw, pw1);
    if (res == "ok") {      
      props.setShowModal(false);
    } else if (res == "old wrong") {
      alert(t("wrongOldPw"))
    } 
  };

  return (
    <>
      <Modal show={props.showModal} onHide={() => props.setShowModal(false)}>
       

        <div className="modal-title1">
          <h4>{t("changePsw")}</h4>
          <CloseButton onClick={() => props.setShowModal(false)} />
        </div>

        <Modal.Body>
          <label className="fl"> {t('oldPsw')} </label>
          <input
            className="fi"
            type="password"
            value={pw}
            onChange={(e) => {
              setPw(e.target.value);  // todo ...
            }}
          />
          <label className="fl"> {t('newPsw')} </label>
          <input
            className="fi"
            type="password"
            value={pw1}
            onChange={(e) => {
              setPw1(e.target.value);
            }}
          />
          <label className="fl"> {t('rePsw')} </label>
          <input
            className="fi"
            type="password"
            value={pw2}
            onChange={(e) => {
              setPw2(e.target.value);
            }}
          />
          <br />
          <br />

          {error && (
            <>
              <label className="fl" style={{ color: "red" }}>
              {t('pwConstrain1')} * 
              </label>
              <label className="fl" style={{ color: "red" }}>
              {t('pwConstrain2')} *
              </label>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <LoadingButton
            onClick={handleSubmit}
            sx={{
              color: "var(--color1)",
              maxWidth: "40%",
              margin: "5%",
              backgroundColor: "var(--color2)",
            }}
            loadingTxt={t('updating')}
          >
            {t('update')}
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChangePasswordModal;
