import { useState } from "react";
import { SERVER_BASE_URL } from "../config";
import Dsignup from "./DesignLogin/Dsignup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import Button from "@mui/material/Button";
import { deleteNotConfirmedUser } from "../helpers";
import { useTranslation } from "react-i18next";
import { globalContext } from "../App";

export default function Signup(props) {
  const [showModal, setShowModal] = useState(false);
  const [code, setCode] = useState("");
  const [userId, setUserId] = useState("");

  const { t } = useTranslation();

  const {setUserLogged} = React.useContext(globalContext)

  const onSubmitSignup = (user, password, email) => {
    if (password.length < 4) {
      alert(t("sigErr"));
      return;
    }

    const url = `${SERVER_BASE_URL}/signup`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // what kind of answer I want back from the server
      },
      body: JSON.stringify({
        user_name: user,
        password: password,
        email: email,
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log("Error signup. response:");
          response.json().then((resJ) => {
            console.log(resJ);
            if (resJ.includes("can not create two users with the same email")) {
              alert(t("sigErr2"));
            } else if (resJ.includes("already exist")) {
              alert(t("sigErr1"));
            } else {
              alert("Signup Error");
            }
          });
          return;
        }

        response.json().then((data) => {
          setUserId(data.user_id);

          // open modal to retrieve email confirmation code
          setShowModal(true);
        });
      })
      .catch((error) => {
        window.alert(`error ${error}`);
      });
  };

  const onVerifyEmail = () => {
    const url = SERVER_BASE_URL + "/verify-email";

    fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json", // what kind of answer I want back from the server
      },
      body: JSON.stringify({ code: code, user_id: userId }),
    }).then((res) => {
      if (res.status != 200) {
        alert(t("errorCodeVerify"));
        return;
      }
      res.json().then((resJ) => {
        localStorage.logToken = resJ.token;
        setShowModal(false);
        setUserLogged(true);
        window.location = "/";
      });
    });
  };

  const handleClose = () => {
    deleteNotConfirmedUser(userId);
    setShowModal(false);
  };

  return (
    <>
      <Dsignup onSubmitSignup={onSubmitSignup} />
      <div>
        <Dialog
          open={showModal}
          onClose={handleClose}          
        >
          <DialogTitle>{t("lastStage")} </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ textAlign: "right" }}>
              {t("lastStageMsg")}
            </DialogContentText>
            <br />
            <div style={{ textAlign: "center" }}>
              <input
                placeholder={t("lastStageCode")}
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("cancel")}</Button>
            <Button onClick={onVerifyEmail}>{t("finish")}</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
