import React, { useState, useEffect } from 'react';
import { connectToWebSocket, sendAuthMessage, sendMessage, setTakeOver } from "../../clients/bbot-client";
import { BBOT_WS_URL } from '../../config';
import "./WebsocketChat.css";

const WebSocketChat = ({ end_user_number, store_id, token, isLocked, onTakeOverChange }) => {
    const [messages, setMessages] = useState([]);
    const [websocket, setWebSocket] = useState(null);
    const [isTakeOver, setIsTakeOver] = useState(isLocked);
    const [newMessage, setNewMessage] = useState('');
    const [pendingMessages, setPendingMessages] = useState([]);
    const [isConnected, setIsConnected] = useState(false);

    const handleWsMsg = (data) => {
        // check type of data by IF's

        // connected status
        if (data.connected) {
            setIsConnected(true);
            return;
        }

        // initial messages. response of auth-request
        if (data.messages) {
            setMessages(data.messages)
            return;
        };

        // msg acknowledge
        if (data.status === 'message sent') {
            setPendingMessages(prev => {
                const [firstPending, ...remainingPending] = prev;
                setMessages(currentMessages => [...currentMessages, { ...firstPending, pending: false }]);
                return remainingPending;
            });
            return;
        }

        // new message arrived from endUser        
        if (data.new_client_message) {
            const newMsg = {
                content: data.new_client_message,
                sent_by: 'client',
                created: new Date().toISOString(),
                pending: false
            };
            setMessages(currentMessages => [...currentMessages, newMsg])
        }

        // new message (response) arrived from AI-bot
        if (data.new_bot_message) {
            const newMsg = {
                content: data.new_bot_message,
                sent_by: 'business',
                created: new Date().toISOString(),
                pending: false
            };
            setMessages(currentMessages => [...currentMessages, newMsg])
        }

        // unknown
        console.log("Unknown WS message:", data);
    };

    const connectWebSocket = () => {
        const ws = connectToWebSocket(BBOT_WS_URL + 'chat-board/ws');

        ws.onopen = () => {
            setIsConnected(true);
            sendAuthMessage(ws, store_id, token, end_user_number);
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            handleWsMsg(data);
        };

        ws.onclose = () => {
            setIsConnected(false);
            console.log('WebSocket connection closed');
        };

        ws.onerror = (error) => {
            setIsConnected(false);
            console.error('WebSocket error:', error);
        };

        setWebSocket(ws);
        return ws;
    };

    const disconnectWebSocket = () => {
        if (websocket) {
            websocket.close();
            setWebSocket(null);
            setIsConnected(false);
        }
    };

    const handleConnectionToggle = () => {
        if (isConnected) {
            disconnectWebSocket();
        } else {
            connectWebSocket();
        }
    };

    useEffect(() => {
        const ws = connectWebSocket();
        return () => {
            if (ws) ws.close();
        };
    }, [store_id, token, end_user_number]);

    useEffect(() => {
        setIsTakeOver(isLocked)
    }, [isLocked, end_user_number])

    const handleTakeOver = async () => {
        const newTakeOverState = !isTakeOver;
        try {
            setTakeOver(websocket, newTakeOverState); // ws-api
            setIsTakeOver(newTakeOverState);  // local state
            onTakeOverChange(newTakeOverState);
        } catch (error) {
            console.error('Error toggling take-over:', error);
        }
    };

    const handleSend = async () => {
        if (!newMessage.trim()) return;

        // Add message to pending messages
        const pendingMessage = {
            content: newMessage,
            sent_by: 'business',
            created: new Date().toISOString(),
            pending: true
        };
        setPendingMessages(prev => [...prev, pendingMessage]);

        try {
            sendMessage(websocket, newMessage);
            setNewMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Error sending message')
        }
    };

    return (
        <div className={`chat-wrapper ${isConnected ? 'connected' : 'disconnected'}`}>
            <div className="chat-header">
                <h3 className='ws-chat-title'>{end_user_number}</h3>
                {!isConnected && <button
                    className={`connection-toggle `}
                    onClick={handleConnectionToggle}
                >
                    {isConnected ? 'dev-Disconnect' : 'אופס. התנתקנו. לחץ להתחברות שוב'}
                </button>}
            </div>
            <div className="chat-container">
                {messages.map((msg, index) => (
                    <div key={index} className={`message ${msg.sent_by}`}>
                        <span className="message-content">{msg.content}</span>
                        <span className="message-time">
                            {new Date(msg.created).toLocaleString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                            })}
                        </span>
                    </div>
                ))}
                {pendingMessages.map((msg, index) => (
                    <div key={`pending-${index}`} className="message business pending">
                        <span className="message-content">{msg.content}</span>
                        <span className="message-time">
                            {new Date(msg.created).toLocaleString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                            })}
                        </span>
                    </div>
                ))}
            </div>

            <div className="chat-controls">
                {isConnected && (
                    <button
                        className={`take-over-btn ${isTakeOver ? 'active' : ''}`}
                        onClick={handleTakeOver}
                    >
                        {isTakeOver ? 'Release Control' : 'Take Over'}
                    </button>
                )
                }
                <div className="input-container">
                    <textarea
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        disabled={!isTakeOver || !isConnected}
                        placeholder={!isConnected ? 'התחבר שוב לשיחה' : isTakeOver ?
                            'Type your message...' : 'Take over to send messages'}

                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleSend();
                            }
                        }}
                    />
                    <button
                        className="send-btn"
                        onClick={handleSend}
                        disabled={!isTakeOver || !newMessage.trim()}
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WebSocketChat;
