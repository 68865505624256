import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { suggest_product_description } from "../clients/ai-client";
import MainButton from "./helpers/MainButton";

function AiDescModal(props) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [suggest, setSuggest] = useState("");

  useEffect(() => {
    props.setShowModal(false);
  }, []);

  const onCloseModal = () => {
    props.setShowAiDesc(false);
    props.setShowModal(true);
  };

  const handlePredict = async () => {
    setShowSpinner(true);
    const res = await suggest_product_description(props.product.mkt);
    // const res = "מפורט/אינפורמטיבי:\nלהתפנק עסיסי, שווארמה עגל איטי קלוי עטוף לחם שטוח חם, עם ירקות פריכים וטפטף עם רוטב טחינה שמנת. מתפוצץ עם תבלינים מזרח תיכוניים אותנטיים, מנה זו היא בטוחה כדי לספק את התשוקה שלך ולהעביר אותך לרחובות ביירות.\n\nידידותי:\nעטפו את בלוטות הטעם שלכם סביב שווארמה עגל מעוררת תיאבון! עסיסי וטעים, מעדן זה מוערם גבוה עם ירקות טריים ורוטב הטחינה הייחודי שלנו. אתה תהיה מכור אחרי ביס אחד בלבד!"
    // await new Promise((x)=>{setTimeout(x, 2000)})
    setSuggest(res.heb);
    setShowSpinner(false);
  };

  const handleInsert = () => {
    props.setDesc(suggest);
    onCloseModal();
  };
  
  return (
    <>
      <Modal show={props.showAiDesc} onHide={onCloseModal} closeButton>
        <Modal.Header
          style={{ direction: "rtl", justifyContent: "space-between" }}
          closeButton
        >
          <Modal.Title style={{ marginLeft: "60%", color: "var(--color1)" }}>
            המלצת מערכת
          </Modal.Title>
        </Modal.Header>

        <ModalBody>
          {suggest.length < 1 && (
            <div style={{ textAlign: "center" }}>
              <p style={{ color: "var(--color1)", textAlign: "center" }}>
                תן לבינה המלאכותית שלנו להציע תיאור למוצר שלך
              </p>

              <MainButton variant="success" onClick={handlePredict}>
                לחץ לקבלת המלצה
              </MainButton>
            </div>
          )}

          <div>
            {showSpinner && <div className="spinner-border"></div>}
            {showSpinner && (
              <div> אנו בודקים התאמות. התהליך עשוי להימשך כ 10 שניות </div>
            )}

            {suggest.length > 1 && (
              <>
                <textarea
                  value={suggest}
                  rows={12}
                  style={{ width: "100%", direction: "rtl" }}
                  onChange={(e) => {
                    setSuggest(e.target.value);
                  }}
                />
                <br />
                <Button variant="danger" onClick={handleInsert}>
                  הכנס למוצר את ההמלצה
                </Button>
              </>
            )}
          </div>
        </ModalBody>
        <Modal.Footer>
          <div>
            <p style={{ textAlign: "center", color: "red" }}>
              שים לב, הינך מוגבל לבקשת 5 המלצות ביום
            </p>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AiDescModal;
