import React, { useContext, useEffect, useState } from "react";
import { getAllEndUsers } from "../../../clients/bbot-client";
import WebSocketChat from "../../WebsocketChat/WebsocketChat";
import "./ChatPage.css";
import { globalContext } from "../../../App";

const ChatsPage = () => {
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [localLockStates, setLocalLockStates] = useState({});
    const { storeData } = useContext(globalContext);

    // Fetch chats with caching in local storage
    const fetchChats = async (purge = false) => {
        const cachedChats = JSON.parse(localStorage.getItem("chats"));
        if (!purge && cachedChats) {
            console.log("using cache for chats");

            setChats(cachedChats);
        } else {
            const res = await getAllEndUsers(storeData.id);

            const sortedChats = res.data.sort((a, b) => {
                // Sort by `is_locked` first (true values come first)
                if (a.is_locked !== b.is_locked) {
                    return a.is_locked ? -1 : 1;
                }
                // If `is_locked` values are the same, sort by `phone_number`
                return a.phone_number.localeCompare(b.phone_number);
            });

            localStorage.setItem("chats", JSON.stringify(res.data));
            setChats(res.data);

            // const initialLockStates = res.data.reduce((acc, chat) => {
            //     acc[chat.id] = false;
            //     return acc;
            // }, {});
            // setLocalLockStates(initialLockStates);

        }
    };

    useEffect(() => {
        if (storeData && chats.length === 0) fetchChats();
    }, [storeData]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleRefresh = () => {
        localStorage.removeItem("chats");
        fetchChats(true);
    };

    const handleTakeOverChange = (isLocked) => {

        // update cache
        // const updatedLockStates = { ...prev, [selectedChat.id]: isLocked };

        // Update the `chats` array with the new lock state
        const updatedChats = chats.map(chat =>
            chat.id === selectedChat.id ? { ...chat, is_locked: isLocked } : chat
        );
        localStorage.setItem("chats", JSON.stringify(updatedChats));


        setLocalLockStates(prev => {
            if (isLocked) {
                return { ...prev, [selectedChat.id]: true };
            } else {
                const { [selectedChat.id]: _, ...rest } = prev;
                return rest;
            }
        });
    };

    useEffect(() => {        
        if (chats.length > 0) {
            let allLocked = [];
            for (const chat of chats) {
                if (chat.is_locked) {
                    allLocked[chat.id] = true;
                }
            }            

            setLocalLockStates(allLocked);
        }
    }, [chats]);


    return (
        <>
            {(selectedChat && isMobile) &&
                <button
                    onClick={() => setSelectedChat(null)}
                    style={{
                        display: 'block',
                        top: '10px',
                        left: '10px',
                        padding: '8px 16px',
                        backgroundColor: '#f0f0f0',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer'
                    }}
                >
                    חזרה לשיחות
                </button>
            }
            <div className="chats-page">
                <div className={`chats-list ${isMobile && selectedChat ? 'hidden' : ''}`}>
                    <div className="cahts-title">
                        <p className="chat-list-title">שיחות עם לקוחות</p>
                        <button onClick={handleRefresh} className="refresh-button">רענן</button>
                    </div>
                    {chats?.map((chat) => (
                        <div
                            key={chat.id}
                            className={`chat-item ${selectedChat?.id === chat.id ? "active" : ""} ${localLockStates[chat.id] ? "locked" : ""
                                }`}
                            onClick={() => setSelectedChat(chat)}
                        >
                            {chat.phone_number}
                        </div>
                    ))}
                </div>
                <div className="chat-window">
                    {selectedChat ? (
                        <WebSocketChat
                            end_user_number={selectedChat.phone_number}
                            store_id={selectedChat.store_id}
                            token={localStorage.getItem("logToken")}
                            isLocked={selectedChat.is_locked || localLockStates[selectedChat.id]}
                            onTakeOverChange={handleTakeOverChange}
                        />
                    ) : (
                        <div className="chat-placeholder">בחר שיחה לצפייה\השתלטות</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ChatsPage;
