import { Button } from "@mui/material";
import React from "react";

function MainButton({ children, ...props }) {
  return (
    <Button
      
      variant="contained"
          color="success"          
          sx={{
              color: "var(--color1)",
              backgroundColor: "var(--color2)"              
          }}
          {...props}
    >
      {children}
    </Button>
  );
}

export default MainButton;
