import { SERVER_BASE_URL } from "../config";
import { getPresignedUrlFromServer, uploadImageToS3 } from "./s3-client";

export async function addStoreImage(image, imageType) {
  const token = localStorage.getItem("logToken");

  let options = {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ about: imageType }),
  };

  let res = await fetch(SERVER_BASE_URL + "/store/image/add", options);

  if (!res.ok) {
    window.alert("Something is wrong 0001");
    console.log("Error on getting presigned url:");
    let resJ = await res.json();
    console.log(resJ);
    return;
  }

  let resJ = await res.json();
  const psurl = resJ.psurl;
  const imageID = resJ.image_id;

  // upload image to s3:
  const isUploaded = await uploadImageToS3(psurl, image);

  if (!isUploaded) {
    window.alert("Error uploading image to s3");
    return;
  }

  // update server that image uploaded to s3:
  options = {
    method: "put",
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ image_id: imageID, new_status: "ok" }),
  };

  res = await fetch(SERVER_BASE_URL + "/store/image/update_status", options);

  if (res.status === 200) {
    console.log("Image uploaded successfully");
  } else {
    console.log("Image uploaded Error");
    console.log(res);
    resJ = res.json();
    console.log(resJ);
  }
}

export async function deleteStoreImage(imageId) {
  console.log(`About to fetch delete store-image of: ${imageId}`);

  const token = localStorage.getItem("logToken");

  const options = {
    method: "delete",
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ image_id: imageId }),
  };

  const res = await fetch(SERVER_BASE_URL + `/store/image/delete`, options);
  if (res.status !== 200) {
    window.alert(`Error at delete image.`);
    console.log("Error at delete image. res:");
    console.log(res);
  }
  const resJ = await res.json();
  console.log(`image deleted. res: ${resJ}`);
}
