import { Button, TextField } from "@mui/material";
import React, { useContext, useState, useTransition } from "react";
import { Modal } from "react-bootstrap";
import DialogContentText from "@mui/material/DialogContentText";
import { callChangeEmail, verifyEmailCode } from "../../../clients/email-client";
import { LoadingButton } from "../../helpers/LoadingButton";
import { globalContext } from "../../../App";
import { useTranslation } from "react-i18next";

function ChangeEmailModal({ email, showModal, setShowModal }) {
  const [newEmail, setNewEmail] = useState("");
  const [code, setCode] = useState("");
  const [showCodeModal, setShowCodeModal] = useState(false);
  
  const {t} = useTranslation()
  const {refetchUserData} = useContext(globalContext)

  const handleSubmit = async () => {
    // ask server to send confirmation code    
    await callChangeEmail(newEmail)
    setShowModal(false)
    setShowCodeModal(true);
  };

  const handleCheckCode = async () => {
    const res = await verifyEmailCode(code)
    if (res == "ok") {
      await refetchUserData()          
      setShowCodeModal(false)      
    } else if (res == "incorrect") {
      window.alert("incorrect")      
    } else {
      console.log("weird")
    }
  };

  return (
    <>
      <Modal
        style={{ direction: "ltr" }}
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header >          
          <h3 style={{color: "var(--color1)"}}>{t('chaneEmail')}</h3>
        </Modal.Header>
        <Modal.Body >
          <p> {email}</p>          
          <TextField
            sx={{ direction: "ltr" }}
            type="email"
            label={t('enterEmail')}
            variant="outlined"
            value={newEmail}
            onChange={(e) => {
              setNewEmail(e.target.value);
            }}
          />
        </Modal.Body>

        <LoadingButton variant="contained"                
          sx={{
            color: 'var(--color1)',
            maxWidth: '40%',
            margin: '5%',
            backgroundColor: 'var(--color2)'
          }}
                onClick={handleSubmit}
                loadingTxt={t('sendingCode')}>
          {t('sendCode')}
        </LoadingButton>        
      </Modal>

      <div>
        <Modal
          show={showCodeModal}
          onHide={() => {
            setShowCodeModal(false);
          }}
        >
          <Modal.Header style={{ direction: "rtl", color: 'var(--color1)' }}>
          {t('lastStageMsg')}
          </Modal.Header>
          <Modal.Body>
            <TextField
              sx={{ direction: "ltr" }}
              label={t('lastStageCode')}
              variant="outlined"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
            

            <LoadingButton variant="contained"                
          sx={{
            color: 'var(--color1)',
            backgroundColor: 'var(--color2)',
            margin: '7px'
          }}
                onClick={handleCheckCode}
                loadingTxt={t('checking')}>
          {t('confirm')}
            </LoadingButton>
            

          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default ChangeEmailModal;
