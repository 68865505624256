import React from "react";
import { Button, Navbar } from "react-bootstrap";
import logo from "../../assets/GenericStrore.png";
import site5 from "../../assets/site5.PNG";
import { useTranslation } from "react-i18next";
import LangToggle from "../LangToggle/LangToggle";
import { useNavigate } from "react-router-dom";

function Welcome(props) {
  
  const nav = useNavigate()

  const handleClick = () => {
    props.setShowWelcome(false);
    nav("/login")
  };
  
  const { t } = useTranslation();

  return (
    <div
      style={{ backgroundColor: "var(--color3)", height: window.innerHeight }}
    >      
      <Navbar bg="var(--color1)" style={{ justifyContent: "space-between" }}>
        <Button
          onClick={handleClick}
          style={{ marginLeft: "3%", backgroundColor: "var(--color2)" }}
        >
          {t("login")}
        </Button>
        <img
          className="only-pc"
          src={logo}
          width={"15%"}
          style={{ marginRight: "5px", marginRight: "3%" }}
        />
      </Navbar>

      <div style={{ display: "flex" }}>
        <div style={{ width: "50%", overflow: "hidden" }} className="only-pc">
          <img width={"100%"} style={{ padding: "5%" }} src={site5}></img>
        </div>
        <div style={{ padding: "5%", paddingTop: '2%' }}>          
          <div style={{textAlign: 'right'}}>            
          <LangToggle isInitialNeeded={true}/>
          </div>
          <br/>
          <h3>{ t("welcomeTitle")}</h3>
          <p>
            {t("welcomeText1")}
            <p style={{ fontWeight: "700" }}> {t("welcomeText2")} </p>
            {t("welcomeText3")}
          </p>

          <Button
            onClick={handleClick}
            style={{ Margin: "10%", backgroundColor: "var(--color1)" }}
          >
            {t('startHere')}
          </Button>
        </div>
      </div>
      <br/>
      <div className="only-mobile">
        <img width={"100%"} style={{ padding: "5%" }} src={site5}></img>
      </div>

    </div>
  );
}

export default Welcome;

// const Footer = () => {
//   const {t} = useTranslation()
//   return (
//     <Box sx={{ width: "100%", bottom: 0, backgroundColor: 'var(--color1)' }}>
//       <BottomNavigation showLabels>
//         <BottomNavigationAction
//           label={t('exampleSite')}
//           onClick={() => {
//             window
//               .open("https://store.genericgs.com/29", "_blank")
//               .focus();
//           }}
//         />        
//       </BottomNavigation>
//       <Typography variant="caption" align="center" color="text.secondary">
//         Powered by GenericGS @ - Jerusalem
//       </Typography>
//     </Box>
//   );
// };
