import React, { useContext, useState } from "react";
import { globalContext } from "../../../App";
import "./contact.css";
import ContactModal from "./ContactModal";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useTranslation } from "react-i18next";

function Contacts() {
  const [showModal, setShowModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState("");

  const {t} = useTranslation()
  const { storeData } = useContext(globalContext);

  const handleOpenContact = (id) => {
    setSelectedContact(id);
    setShowModal(true);
  };
  
  return (
    <>
      <div>
        {storeData && (
          <>
            <div
              style={{
                display: "flex",
                color: "var(--color1)",
                fontWeight: "700",
                margin: "1%",
                padding: "2%",
                textAlign: 'center',
                
              }}
            >
              <p style={{ width: "10%"  }}> {t('contactDone')} </p>              
              <p style={{ width: "40%" }}> {t('date')} </p>
              <p style={{ width: "30%" }}> {t('orderClient')} </p>
              {/* <p style={{ width: "5%",  }}> {t('contactDone')} </p>
              <p style={{ width: "10%", marginRight: "12%" }}> ID </p>
              <p style={{ width: "40%" }}> {t('date')} </p>
              <p style={{ width: "30%" }}> {t('orderClient')} </p> */}
            </div>
            {storeData.contacts.sort().map((m) => {              
              return (
                <div
                  style={{textAlign: 'center'}}
                  key={m.id}                  
                  className="line1 gs-list"
                  onClick={() => handleOpenContact(m.id)}
                >
                  {m.status ? (
                    <CheckBoxIcon style={{ margin: "0 5%" }} />
                  ) : (
                    <CheckBoxOutlineBlankIcon style={{ margin: "0 5%" }} />
                  )}                  
                  <p style={{ width: "40%" }}>
                    {m.created.slice(0, 10)} {m.created.slice(11, 16)}
                  </p>
                  <p style={{ width: "30%" }}> {m.name} </p>
                </div>
              );
            })}

            {storeData.contacts.length === 0 && <div> {t('noContacts')} </div>}
          </>
        )}
      </div>

      {showModal && (
        <ContactModal
          showModal={showModal}
          setShowModal={setShowModal}
          contactId={selectedContact}
        />
      )}
    </>
  );
}

export default Contacts;
