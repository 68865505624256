import { SERVER_BASE_URL } from "../config";

export async function suggest_product_description(mkt) {
    
    console.log("About to suggest product description")

    const url = SERVER_BASE_URL + "/ai/product/description"

    const token = localStorage.getItem("logToken");

    const options = {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({"mkt": mkt}),
    };
    
    const res = await fetch(url, options);
    const resJ = await res.json()
    console.log(resJ)
    return resJ
}