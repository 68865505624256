import React, { useContext, useState } from "react";
import AddProduct from "./AddProduct";
import EditProductModal from "./EditProductModal";
import { globalContext } from "../../../App";
import { TextField } from "@mui/material";
import MainButton from "../../helpers/MainButton";
import { useTranslation } from "react-i18next";

export const getCategories = (storeData) => {
  const categories = storeData.products.map((p) => {
    return p.category;
  });
  return [...new Set(categories)];
};

function Products() {
  const [searchPrd, setSearchPrd] = useState("");
  const [searchCat, setSearchCat] = useState("");
  const [showAddPrd, setShowAddPrd] = useState(false);

  const { storeData } = useContext(globalContext);
  const {t} = useTranslation()

  const filterProducts = () => {
    const products = storeData.products.filter(
      (p) =>
        p.name.includes(searchPrd) &
        (p.category.includes(searchCat) | (searchCat == t('category')))
    );
    return products;
  };

  return (
    <>
      {storeData && (
        <>
          <br />
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "100%",
                minWidth: "180px",
              }}
            >
              <div
                style={{
                  padding: "2px",                  
                  maxWidth: "900px",                  
                  margin: 'auto',
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {storeData.products.length > 0 && (
                  <>
                    <TextField
                      style={{
                        width: "70%",
                        margin: '0.5%'
                      }}
                      placeholder={t('free_search')}
                      onChange={(e) => {
                        setSearchPrd(e.target.value);
                      }}
                    />
                    <select
                      style={{
                        width: "30%",    
                        margin: '0.5%'
                      }}
                      placeholder={t('cat_search')}
                      onChange={(e) => {
                        setSearchCat(e.target.value);
                      }}
                    >
                      <option> {t('category')} </option>
                      {getCategories(storeData).map((c) => {
                        return <option> {c} </option>;
                      })}
                    </select>
                  </>
                )}
                <MainButton
                  style={{
                    padding: "2px",
                    margin: '0.5%',
                    marginRight: "3px",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    setShowAddPrd(true);
                  }}
                >
                  {t('add_p')}
                </MainButton>
              </div>

              <br />
              <div>
                {showAddPrd && (
                  <AddProduct
                    setShowAddPrd={setShowAddPrd}
                    showAddPrd={showAddPrd}
                  />
                )}
                <div className="only-pc">
                  <br />
                  <br />
                  <br></br>
                </div>
              </div>
            </div>

            {storeData.products.length > 0 && <div
              // className="only-pc"
              style={{
                display: "flex",                
                color: "var(--color1)",
                flexDirection: "row",                                
                marginTop: "4px",
                padding: "10px",
              }}
            >
              
              <div className="only-pc" style={{ width: "30%" }}>{t('mkt')}</div>
              <div style={{ width: "30%" }}>{t('p_name')}</div>
              <div style={{ width: "30%" }}>{t('category')}</div>
              <div style={{ width: "20%", textAlign: "center" }}>{t('price')}</div>
              <div style={{ width: "20%" }}>{t('stk')}</div>
            </div>}

            {filterProducts().map((p) => {
              return <EditProductModal key={p.mkt} product={p} />;
            })}
          </div>
        </>
      )}
    </>
  );
}

export default Products;
