import axios from "axios";
import { SERVER_BASE_URL } from "../config";

export async function callChangeEmail(newEmail) {
  const url = SERVER_BASE_URL + "/email/update/send_code";
  const token = localStorage.getItem("logToken");

  try {
    const resJ = await axios.post(
      url,
      { email: newEmail },
      {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    console.assert(resJ.status == 200);
    return "ok";
  } catch (error) {
    window.alert("Error at change email");
    console.log("error change email:");
    console.log(error);
  }
}

export async function verifyEmailCode(code) {
  const url = SERVER_BASE_URL + "/email/update/verify";
  const token = localStorage.getItem("logToken");

  try {
    const resJ = await axios.post(
      url,
      { code: code },
      {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (resJ.status == 401) {
      return "incorrect";
    }
    console.assert(resJ.status == 200);
    return "ok";
  } catch (error) {
    window.alert("Error at verify email-code");
    console.log("error verify:");
    console.log(error);
  }
}
