import { Modal } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { LoadingButton } from "./helpers/LoadingButton";

import { globalContext } from "../App";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

function AddImageModal({ handleClose, handleCommit, showAddImageModal }) {
  // args should be: props.mkt, props.handleClose props.showAddImageModal

  const [image, setImg] = useState("");
  const { setAlertData } = useContext(globalContext);
  const { t } = useTranslation();
  // TODO: fix naming
  const _handleCommit = async () => {
    await handleCommit(image);
    toast.success(t("img_added_msg"))
    handleClose();
  };

  return (
    <Modal
      show={showAddImageModal}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header
        style={{ justifyContent: "space-between" }}
        closeButton
      >
        <Modal.Title style={{ marginLeft: "60%", color: "var(--color1)" }}>
          {t("chooseImg")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <br />
        <input
          id="id_img1"
          type="file"          
          accept="image/*"
          onChange={(e) => {
            setImg(e.target.files[0]);
          }}
        />

        <LoadingButton
          variant="contained"
          onClick={_handleCommit}
          loadingTxt={t("updating")}
        >
          {t("update")}
        </LoadingButton>
      </Modal.Body>
    </Modal>
  );
}

export default AddImageModal;
