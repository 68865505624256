import imageCompression from "browser-image-compression";

export const compressImage = async (imageFile, maxSizeMB = 0.4) => {
  const currentSizeMB = imageFile.size / 1024 / 1024;
  if (currentSizeMB <= maxSizeMB) {
    // console.log("No compression needed");
    return imageFile;
  }

  console.log(imageFile);
  const options = {
    maxSizeMB,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    console.log("Compressed image");
    console.log(compressedFile);
    return compressedFile;
  } catch (error) {
    console.log(error);
  }
};
