import React, { useContext, useState } from "react";
import { SERVER_BASE_URL } from "../config";
import SignIn from "./DesignLogin/SignIn";
import { useNavigate } from "react-router-dom";
import { globalContext } from "../App";

function Login(props) {

  const [error, setError] = useState(false ) // show error on wrong username/password
  const nav = useNavigate()
  const {refetchStoreData, refetchUserData, setUserLogged } = useContext(globalContext)
  
  const onSubmitUserPassword = (user, password) => {
    const url = `${SERVER_BASE_URL}/signin`;    
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // what kind of answer I want back from the server
      },
      body: JSON.stringify({
        username: user,
        password: password,
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          setError(true)          
          return
        }

        response.json().then((data) => {
          // save token
          localStorage.logToken = data.token;

          // call props
          setUserLogged(true);

          // fetch data 
          refetchUserData()
          refetchStoreData().then(nav('/'))          
          
        });
      })
      .catch((error) => {
        window.alert(`error ${error}`);
      });
  };

  return <SignIn onSubmitUserPassword={onSubmitUserPassword} error={error} />;
}

export default Login;
