import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AlertModal = ({ alertData, setAlertData }) => {

  const { t } = useTranslation()
  
  const internalOnClick = async () => {
    if (alertData.onClick) {
      await alertData.onClick();
  }

    const newAlertData = { ...alertData };
    newAlertData.msg = "";
    newAlertData.btnMsg = t("confirm");
    newAlertData.active = false;
    setAlertData({ ...newAlertData });
  };
  return (
    <div>
      <Modal show={alertData.active} backdrop="static">
        <Modal.Body style={{ textAlign: "center", color: 'var(--color1)' }}>{alertData.msg}</Modal.Body>
        
          <div style={{ textAlign: "center", margin: '5%' }}>
            <Button  onClick={internalOnClick}>              
              {alertData.btnMsg}
                  </Button>                  
          </div>
        
      </Modal>
    </div>
  );
};

export default AlertModal;
