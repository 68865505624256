import React, { useContext, useState } from "react";
import { globalContext } from "../../../App";
import { Button } from "@mui/material";
import ChangeEmailModal from "./ChangeEmailModal";
import ChangePasswordModal from "./ChangePasswordModal";
import { useTranslation } from "react-i18next";

function Account() {
  const { userData } = useContext(globalContext);
const {t} = useTranslation()

  const [usernameModal, setUsernameModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);

  return (
    <>
      <div style={{maxWidth: '500px', margin: 'auto'}}>
        <label className="fl"> {t('username')} </label>
        <Button
          disabled
          sx={{ width: "300px", maxWidth: "400px", overflow: 'hidden', maxWidth: '90%' }}
          onClick={() => {
            setUsernameModal(true);
          }}
          variant="outlined"
        >
          {userData && userData.username}
        </Button>
        <br />
        <label className="fl"> {t('email')} </label>
        <Button
          sx={{ width: "300px", maxWidth: "400px", overflow: 'hidden', maxWidth: '90%' }}
          onClick={() => {
            setEmailModal(true);
          }}
          variant="outlined"
        >
          {userData.email}
        </Button>
        <br />
        <br />
        <br />
        <Button
          variant="outlined"
          onClick={() => {
            setPasswordModal(true);
          }}
          sx={{ color: "var(--color1)", marginLeft: "3px" }}
        >
          {t('changePsw')}
        </Button>
      </div>

      <ChangeEmailModal
        email={userData.email}
        showModal={emailModal}
        setShowModal={setEmailModal}
      ></ChangeEmailModal>

      <ChangePasswordModal
        showModal={passwordModal}
        setShowModal={setPasswordModal}
      />
    </>
  );
}

export default Account;
