import { BBOT_REST_URL } from "../config";
import axios from 'axios';


// await active_connections[tuple_id].send_json({"new_client_message": new_message.content})


export const getAllEndUsers = async (store_id) => {
        
    // token = "f308aa19548f7248c9be7bd48a052f35bedb9d60"
    const token = localStorage.getItem("logToken");
    // console.log(store_id, token);
    
    const url = `${BBOT_REST_URL}chat-board/end-users/all`;
    const body = { store_id, token };    
    const res = await axios.post(url, body);
    return res;
};

export const connectToWebSocket = (url) => {
    return new WebSocket(url);
};

export const setTakeOver = (websocket, value) => {
    websocket.send(JSON.stringify({ "set-lock": value }));
}

export const sendMessage = (websocket, content) => {
    websocket.send(JSON.stringify({ new_message: content }));
};
export const sendAuthMessage = (websocket, storeId, token, endUserPhone) => {
    const authMessage = {
        auth: true,
        store_id: storeId,
        token: token,
        end_user_phone: endUserPhone
    };
    websocket.send(JSON.stringify(authMessage));
};
