export const SERVER_BASE_URL = "https://genericgs.com/api/v1";
// export const SERVER_BASE_URL = 'https://bbot.genericgs.com/gs/api';
// export const SERVER_BASE_URL = "http://127.0.0.1:8000/api/v1";

// export const BBOT_WS_URL = 'ws://127.0.0.1:8000/'
// export const BBOT_REST_URL = 'http://127.0.0.1:8000/'

export const BBOT_WS_URL = 'wss://bbot.genericgs.com/'
export const BBOT_REST_URL = 'https://bbot.genericgs.com/'

// export const BASE_URL = "http://127.0.0.1:3000";
// export const APP_BASE_URL = 'https://bbot.genericgs.com/gs/dashboard';
