import React, { useContext, useEffect, useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { LoadingButton } from "../../helpers/LoadingButton";
import { showAlert, updateStore } from "../../../helpers";
import { globalContext } from "../../../App";
import { useTranslation } from "react-i18next";
import ColorsSuggestion from "../../ColorsSuggestion";
import toast from "react-hot-toast";

function Settings() {
  const [notifyOrder, setNotifyOrder] = useState(true);
  const [notifyContact, setNotifyContact] = useState(true);
  const [allowOrder, setAllowOrder] = useState(true);
  const [orderEmailReq, setOrderEmailReq] = useState(false);
  const [showColors, setShowColors] = useState(false)
  const [allowContact, setAllowContact] = useState(true);
  const [allowShipping, setAllowShipping] = useState(true);
  const [updateButton, setUpdateButton] = useState(false)
  const [color, setColor] = useState({
    1: "#0C134F",
    2: "#1D267D",
    3: "#5C469C",
    4: "#D4ADFC",
  });

  const [OrderMsg, setOrderMsg] = useState("")
  const [language, setLanguage] = useState("")

  const { storeData, setStoreData, setAlertData } = useContext(globalContext);
  const {t} = useTranslation()

  useEffect(() => {
    if (storeData) {
      setColor({
        1: storeData.color1,
        2: storeData.color2,
        3: storeData.color3,
        4: storeData.color4,
      });
      setNotifyOrder(storeData.notify_order);
      setNotifyContact(storeData.notify_contact);
      setAllowOrder(storeData.allow_orders);
      setAllowContact(storeData.allow_contact);
      setOrderEmailReq(storeData.order_email_confirmation_required);
      setAllowShipping(storeData.allow_shipping);
      setOrderMsg(storeData.end_order_msg)
      setLanguage(storeData.language)

    }
  }, [storeData]);
  

  const updateColor = (e, num) => {
    const newColor = { ...color, [num]: e.target.value };
    setColor(newColor);
    setUpdateButton(true)
  };

  const handleSubmit = async () => {
    const data = {
      color1: color["1"],
      color2: color["2"],
      color3: color["3"],
      color4: color["4"],
      notify_order: notifyOrder,
      notify_contact: notifyContact,
      allow_orders: allowOrder,
      allow_contact: allowContact,
      order_email_confirmation_required: orderEmailReq,
      allow_shipping: allowShipping,
      end_order_msg: OrderMsg,
      language,
    };

    await updateStore(data);    
    // refetch storeData:
    const newStoreData = {
      ...storeData,
      color1: color["1"],
      color2: color["2"],
      color3: color["3"],
      color4: color["4"],
      notify_order: notifyOrder,
      notify_contact: notifyContact,
      allow_orders: allowOrder,
      allow_contact: allowContact,
      order_email_confirmation_required: orderEmailReq,
      allow_shipping: allowShipping,
      end_order_msg: OrderMsg,
      language,
    };
    setStoreData(newStoreData);
    setUpdateButton(false)
  };

  return (
    <div style={{maxWidth: '700px', margin: 'auto'}}>
      <h4> {t('generalSettings')} </h4>
            
      <span> {t("language")}  </span>
      <select value={language} style={{ margin: '2%' }} onChange={(e) => {
        setUpdateButton(true)
        setLanguage(e.target.value)
        // showAlert(setAlertData, t("changeLangAlert"));
        toast(t("changeLangAlert"), {style: {backgroundColor: 'var(--color2)'}})

      }}>        
        <option value="en"> English </option>        
        <option value="he" > עברית </option>
        <option value="fr" > France </option>
        <option value="es" > Español </option>
      </select>
      <br/>
      <br/>
      <div
        onClick={() => {
          setAllowOrder((p) => {
            return !p;
          });
          setUpdateButton(true)
        }}
      >
        {allowOrder && <CheckBoxIcon />}
        {!allowOrder && <CheckBoxOutlineBlankIcon />}
        <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
        {t('genSetText1')}
        </label>
      </div>
      <div
        onClick={() => {
          setOrderEmailReq((p) => {
            return !p;
          });
          setUpdateButton(true)
        }}
      >
        {orderEmailReq && <CheckBoxIcon />}
        {!orderEmailReq && <CheckBoxOutlineBlankIcon />}
        <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
        {t('genSetText2')}
        </label>
      </div>      
      <div
        onClick={() => {
          setAllowContact((p) => {
            return !p;
          });
          setUpdateButton(true)
        }}
      >
        {allowContact && <CheckBoxIcon />}
        {!allowContact && <CheckBoxOutlineBlankIcon />}
        <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
        {t('genSetText3')}
        </label>
      </div>
      <div
        onClick={() => {
          setAllowShipping((p) => {
            return !p;
          });
          setUpdateButton(true)
        }}
      >
        {allowShipping && <CheckBoxIcon />}
        {!allowShipping && <CheckBoxOutlineBlankIcon />}
        <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
        {t('genSetText4')}
        </label>
      </div>
      <br />
      <br />
      <div>
        <h4> {t('genSetText5')} </h4>
        <br />
        <div
          onClick={() => {
            setNotifyOrder((o) => {
              return !o;
            });
            setUpdateButton(true)
          }}
        >
          {notifyOrder && <CheckBoxIcon />}
          {!notifyOrder && <CheckBoxOutlineBlankIcon />}
          <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
          {t('genSetText6')}
          </label>
        </div>        

        <div
          onClick={() => {
            setNotifyContact((o) => {
              return !o;
            });
            setUpdateButton(true)
          }}
        >
          {notifyContact && <CheckBoxIcon />}
          {!notifyContact && <CheckBoxOutlineBlankIcon />}
          <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
          {t('genSetText7')}
          </label>
        </div>
        <br />
        <br />
                    
          <h4> {t('genSetText8')} </h4>
          <textarea value={OrderMsg} onChange={(e) => {
            setOrderMsg(e.target.value)
            setUpdateButton(true)
          }} style={{ padding: '2%' }}>
            
          </textarea>
          <br />
        <br />
        <button 
        className="color-button"
          onClick={() => { setShowColors((prev) => { return !prev }) }}>
          {t('colorsSettings')}
        </button>
        {showColors && <>
          <ColorsSuggestion setColor={setColor} setUpdateButton={setUpdateButton} updateColor={updateColor} color={color} />
        </>}
          <br />
          
          <br />
        <br />
          <LoadingButton
            onClick={handleSubmit}
            variant="contained"
            disabled={!updateButton}
            sx={{
              backgroundColor: "var(--color2)",
              width: "300px",
              maxWidth: "50%",
            }}
          >
            {t('update')}
          </LoadingButton>
      </div>
    </div>
  );
}

export default Settings;
