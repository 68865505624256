import axios from "axios";
import { SERVER_BASE_URL } from "../config";

export const changePassword = async (oldPw, newPw) => {
  const url = SERVER_BASE_URL + "/user/update/password";
  const data = {
    new_password: newPw,
    old_password: oldPw,
  };

  const token = localStorage.getItem("logToken");
  try {
    const res = await axios.post(url, data, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (res.status != 200) {
      window.alert("Error 9042!");
      return;
    }
    return "ok";
  } catch (error) {
    if (error.response.data.includes("old password incorrect")) {
      return "old wrong";
    }
    console.log(error.response.data);
    window.alert(error);
  }
};

export const contactSystem = async (content) => {
  const url = SERVER_BASE_URL + "/contact/system";
  const token = localStorage.getItem("logToken");
  const data = { content: content };
  const headers = {
    Authorization: `Token ${token}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.post(url, data, { headers: headers });
    if (res.status != 200) {
      window.alert(res);
      console.log("Error contact system");
      console.log(res);
      return;
    }
    return "ok";
  } catch (error) {
    window.alert(error);
  }
};

export async function sendForgotPasswordCode(email) {
  const url = SERVER_BASE_URL + "/user/update/reset-password-a";

  const data = { email: email };
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.post(url, data, { headers: headers });

    if (res.status != 200) {
      console.log(res);
      window.alert(res);
      return;
    }
    return "ok";
  } catch (error) {
    if (error.response.data == "email not found") {
      return "email not found";
    }
    alert("Error");
    console.log(error);
    return "error";
  }
}

export async function resetPasswordByCode(email, code, newPw) {
  const url = SERVER_BASE_URL + "/user/update/reset-password-b";

  const data = { email: email, code: code, new_password: newPw };
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.post(url, data, { headers: headers });

    if (res.status != 200) {
      window.alert(res);
      console.log("Error");
      console.log(res);
      return;
    }
    return "ok";
  } catch (error) {
    if (error.response.data === "wrong code") {
      return "wrong code";
    }

    window.alert(error);
  }
}
