import React, { useContext, useState } from "react";
import Order from "./Order";
import { globalContext } from "../../../../App";
import { useTranslation } from "react-i18next";

function Orders() {
  const [filter, setFilter] = useState(false);

  const { storeData } = useContext(globalContext);
  const { t } = useTranslation()
  
  const filterByStatus = (status) => {
    if (status === "- -") {
      setFilter(false);
      return;
    } else {
      setFilter(status);
    }
  };

  return (
    <>
      {storeData && (
        <div style={{ display: "flex", flexDirection: "column", margin: 'auto', maxWidth: '95%' }}>
          {storeData.orders.length > 1 && (
            <div >
              <label className="fl" htmlFor="id_search">
              {t('filterOrders')}
              </label>
              <select
                style={{ marginRight: "5%" }}
                name="status"
                id="id_status"
                onChange={(e) => {
                  filterByStatus(e.target.value);
                }}
              >
                <option value="- -"> {t('filterAllOrders')}</option>
                <option value="done">{t('finishOrders')}</option>
                <option value="pending">{t('waitingOrders')}</option>
                <option value="decline">{t('rejectOrders')}</option>
              </select>
            </div>
          )}
          <br className="only-pc"/>
          <div
            className="only-pc"
            style={{
              display: "flex",
              flexDirection: "row",
              color: "var(--color1)",
              fontWeight: "700",
              marginRight: "2%",
              textAlign: 'center',
            }}
          >
            <p style={{ width: "28%" }}> {t('orderNum')} </p>
            <p style={{ width: "25%" }}> {t('orderClient')} </p>
            <p style={{ width: "20%" }}> {t('status')} </p>
            <p style={{ width: "20%", textAlign: "center" }}> {t('date')} </p>
          </div>
          <div>
            {storeData.orders && (
              <>
                {storeData.orders.length === 0 && <div> {t('noOrders')} </div>}
                {!filter && (
                  <div style={{ textAlign: "center" }}>
                    {storeData.orders.map((order) => {
                      console.log(order);
                      return <Order key={order.id} order={order} />;
                    })}
                  </div>
                )}

                {filter && (
                  <div style={{ textAlign: "center" }}>
                    {storeData.orders
                      .filter((order) => {
                        return order.status === filter;
                      })
                      .map((order) => {
                        return <Order key={order.id} order={order} />;
                      })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Orders;
