import DeleteIcon from "@mui/icons-material/Delete";
import { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import AddImageModal from "../../AddImageModal";
import {
  addProductImage,
  deleteProductImage,
} from "../../../clients/product-client";
import { LoadingButton } from "../../helpers/LoadingButton";
import { globalContext } from "../../../App";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

function ProductImage(props) {
  const [showAddImageModal, setShowAddImageModal] = useState(false);
  const { refetchStoreData } = useContext(globalContext);
  const {t} = useTranslation()

  const handleDeleteImage = async (imageId) => {
    await deleteProductImage(imageId);
    await refetchStoreData();
    toast.error(t("imageDeleted"))
  };

  return (
    <>
      <Modal
        show={props.showImageModal}
        onHide={() => {
          props.handleClose(false);
        }}
      >
        <Modal.Header
          style={{  justifyContent: "space-between" }}
          closeButton
        >
          <Modal.Title style={{ marginLeft: "40%", color: "var(--color1)" }}>
          {t('imgs_for')}
            {props.product.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {props.product.images.map((image) => {
            return (
              <div key={image.id} style={{ display: "flex", gap: "2%" }}>
                <img
                  className="d-block 
                   m-1 image-cropper"
                  src={image.url}
                  alt={t('p_ua')}
                />

                <LoadingButton
                  variant="outlined"
                  style={{ maxHeight: "30px", marginTop: "5%" }}
                  color="error"
                  onClick={async () => {
                    await handleDeleteImage(image.id);
                  }}
                  loadingTxt={t('deleting')}
                >
                  <DeleteIcon />
                </LoadingButton>
                <br />
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowAddImageModal(true);
              props.setShowImages(false);
            }}
          >
            {t('add_i')}
          </Button>
        </Modal.Footer>
      </Modal>

      <AddImageModal
        handleClose={() => {
          setShowAddImageModal(false);
          props.setShowImages(true);
        }}
        showAddImageModal={showAddImageModal}
        handleCommit={async (image) => {
          // TODO: this is not the best way to do it, please fix
          await addProductImage(props.product.mkt, image).then(
            refetchStoreData
          );
        }}
      />
    </>
  );
}

export default ProductImage;
