import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://genericgs.com/">
        GenericStore
      </Link>
      {new Date().getFullYear()}      
    </Typography>
  );
}

const theme = createTheme();

export default function Dsignup(props) {
  const { t } = useTranslation();
  const nav = useNavigate()
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    console.log(
      `${data.get("email")}, ${data.get("userName")}, ${data.get("password")}`
    );
    props.onSubmitSignup(
      data.get("userName"),
      data.get("password"),
      data.get("email")
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",            
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">            
            {t("signup")}
          </Typography>
          <Button           
              onClick={()=>{nav("/login")}}
              fullWidth
              variant="contained"
              style={{backgroundColor: 'var(--color2)'}}
              sx={{ mt: 3, mb: 2 }}
            >
              {t('alreadyUser')}
            </Button>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="userName"
              dir="ltr"
              label={t('usename')}
              name="userName"
              autoComplete="userName"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              dir="ltr"
              fullWidth
              name="password"
              label={t('password')}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              required
              dir="ltr"
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
            />
            <p style={{ textAlign: "right", paddingTop: "0" }}>
            {t('emailConf')}
            </p>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('signup')}
            </Button>
          
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
