import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { LoadingButton } from "./helpers/LoadingButton";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { contactSystem } from "../clients/password-client";
import { useTranslation } from "react-i18next";

function ContactSystem() {
  const [showModal, setShowModal] = useState(true);
  const [content, setContent] = useState("");
const {t} = useTranslation()
  const nav = useNavigate();

  const handleSubmit = async () => {
    if (content.length < 5) {
      alert(t('alert1'));
      return;
    }
    await contactSystem(content);
    alert(t('sysSent'));
    nav(-1);
  };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          nav(-1);
        }}        
      >
        <Modal.Header className="modal-header1" closeButton>
          <Modal.Title className="modal-title1">
          {t('sysTitle1')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{textAlign: 'center'}}>
          <label className="fl"> {t('contactContent')} </label>
                  <textarea
                      style={{ width: '80%' }}
                      rows={5}
                      maxLength={500}
            onChange={(e) => {
              setContent(e.target.value);
            }}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              nav(-1);
            }}
          >
            {t('cancel')}
          </Button>

          <LoadingButton
            variant="contained"
            style={{ backgroundColor: "var(--color2)" }}
            onClick={handleSubmit}
            loadingTxt={t('sending')}
          >
            {t('send')}
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ContactSystem;
