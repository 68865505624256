import React from "react";

import "./store_image_style.css";
import StoreImage from "./StoreImage";

const StoreImages = () => {
  return (
    <>
      <div style={{
        margin: 'auto',        
        display: 'flex',
        flexWrap: 'wrap',   
        // justifyContent: 'space-between'
      }}>
        <StoreImage about="logo"/>
        <StoreImage about="main"/>
        <StoreImage about="sec1"/>
        <StoreImage about="sec2"/>
        <StoreImage about="sec3"/>
      </div>
    </>
  );
};

export default StoreImages;
