import React, { useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  addStoreImage,
  deleteStoreImage,
} from "../../../clients/store-image-client";
import { globalContext } from "../../../App";
import { LoadingButton } from "../../helpers/LoadingButton";
import AddImageModal from "../../AddImageModal";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import MainButton from "../../helpers/MainButton";


function StoreImage({ about }) {
  const [sImage, setSImage] = useState("");
  const [showAddImageModal, setShowAddImageModal] = useState(false);
  const { storeData, refetchStoreData, setStoreData } =
  useContext(globalContext);
  
  const {t} = useTranslation()
  useEffect(() => {
    if (storeData) {
      const image = storeData.images.filter((im) => {
        return im.about == about;
      });
      if (image.length > 0) {
        setSImage(image[0]);
      } else {
        setSImage(false);
      }
    }
  }, [storeData]);
  
  const aboutMap = {
    logo: { title: t('aboutLogo'), desc: t('logoImageDesc')},
    main: { title: t('mainImageTitle'), desc: t('mainImageDesc')},
    sec1: { title: t('sec1ImageTitle'), desc: t('sec1ImageDesc')},
    sec2: { title: t('sec1ImageTitle'), desc: t('sec1ImageDesc')},
    sec3: { title: t('sec1ImageTitle'), desc: t('sec1ImageDesc')},   
  };

  const onAddImage = async (image) => {
    await addStoreImage(image, about);
    await refetchStoreData();
  };

  const handleDelete = async () => {
    await deleteStoreImage(sImage.id);
    setSImage(false);
    setStoreData((prevStoreData) => {
      const newImages = prevStoreData.images.filter((_image) => {
        return _image.id != sImage.id;
      });
      return {
        ...prevStoreData,
        images: newImages,
      };
    });
  };

  return (
    <div style={{margin: '3%'}}>
     <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>{aboutMap[[about]].title}</Card.Title>
        <Card.Text>
        {aboutMap[[about]].desc}
        </Card.Text>
      </Card.Body>
     
        <Card.Body>
          {sImage.url &&
            <LoadingButton
              variant="text"
              sx={{ color: "red" }}
              onClick={async () => {
                await handleDelete();
              }}
              loadingTxt={t('deleting')}
              >
              {t('delete')}
              <DeleteIcon />
            </LoadingButton>}     

          <MainButton onClick={()=>{setShowAddImageModal(true)}}>
            {t('storeImageBut')}
          </MainButton>
      </Card.Body>
              <Card.Img variant="top" src={
                  sImage
                    ? sImage.url
                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYx9x8u0lXMF5HNl-R2wDBIuQO8Q_xv8suPw&usqp=CAU"
                }
             />
    </Card>          
      <div>
        <AddImageModal
          handleClose={() => {
            setShowAddImageModal(false);
          }}
          showAddImageModal={showAddImageModal}
          handleCommit={onAddImage}
        />
      </div>
    </div>
  );
}

export default StoreImage;
