import React, { useState } from "react";
import { Button } from "react-bootstrap";

function Api() {
  const [showOrderExample, setShowOrderExample] = useState(false);
  const [showContactExample, setShowContactExample] = useState(false);

  const contactExample = `
// Javascript

const params = {        
  store_id: <id>,
  name: <name>,
  telephone: <telephone>,
  email: <email>,
  content: <content>,
};

const options = {
  headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
  },
  method: "POST",
  body: JSON.stringify(params),
};

fetch("https://genericgs.com/api/v1/contact", options);
  `
  const orderExample = `    
// Javascript

const params = {
  order: {'mkt1': 2, 'mkt2': 1, 'mkt3': 5 ...},
  store_id: <id>,
  client_name: <name>,
  client_telephone: <tel>,  
  client_email: <email>, 
  order_comments: <comment>, 
};

const options = {
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
  },
  method: "POST",
  body: JSON.stringify(params),
};

fetch("https://genericgs.com/api/v1/order/new", options);    
    `;
  return (<>
    <h1 style={{ color: 'var(--color1)', paddingLeft: '30px', textAlign: "left" }}> Generic Store API </h1>
    <div style={{ direction: "ltr", textAlign: "left", paddingLeft: "20px" }}>      
      <br/>
      <h5> Get store data </h5>
      <p> GET https://genericgs.com/api/v1/store?storeid=[id] </p>
      <h5> Send email confirmation </h5>
      <p>        
        GET
        https://genericgs.com/api/v1/order/email_confirmation?email=[email]&store_id=[id]{" "}
      </p>
      <h5> Send new order </h5>
      <p> POST https://genericgs.com/api/v1/order/new </p>
      <Button
        onClick={() => {
          setShowOrderExample((prev) => {
            return !prev;
          });
        }}
      >
        {showOrderExample ? "Hide" : "Show Example"}
      </Button>
      <div style={{ paddingLeft: "5px", backgroundColor: "var(--color2)" }}>
        {showOrderExample && <pre>{orderExample}</pre>}
      </div>
      <br />
      <h5> Contact store </h5>
      <p> POST https://genericgs.com/api/v1/contact </p>
      <Button
        onClick={() => {
          setShowContactExample((prev) => {
            return !prev;
          });
        }}
      >
        {showContactExample ? "Hide" : "Show Example"}
      </Button>
      <div style={{ paddingLeft: "5px", backgroundColor: "var(--color2)" }}>
        {showContactExample && <pre>{contactExample}</pre>}
      </div>
      <br />
      
    </div>
  </>);
}

export default Api;
